import CTAButton from "./CTAButton";

const Header = () => {
    return (
        <div className="w-screen h-[40px] md:h-[60px] fixed top-0 left-0 flex justify-center items-center gap-2 md:gap-4 bg-[#FEC330] z-20">
            <div className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
            Flex your music knowledge & earn bragging rights!
            </div>
            <CTAButton />
        </div>
    )
}

export default Header;
